import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Background from './img/circles_bg.svg'
import PUPanel from './components/layout/PUPanel'

class HomePageRedirect extends Component {
    render() {
        const products = this.props.auth.products.map(p => p.product).filter(p => p !== 'plmaccreditationonly')
        if (products.length > 0) {
            return <Redirect to={{
                pathname: '/product/' + products[0],
            }} />
        }

        return (
            <div style={{
                backgroundImage: 'url("' + Background + '")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                overflow: 'hidden',
                backgroundPosition: 'center',

            }}>
                <div className="container pb-5 pt-5" >
                    <div className="row justify-content-center">
                        <PUPanel color="blue" title="No Active Products">
                            You do not currently have any active products associated with your account. This may be because the licence date has expired, or your organisation lead has not assigned you a licence.
                            Please <Link to="/contact-us">contact us</Link> if you require any support.
                        </PUPanel>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
    products: state.products
});

export default connect(mapStateToProps)(HomePageRedirect);